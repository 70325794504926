<template>
  <svg
    :viewBox="`0 0 ${width} ${height}`"
    :class="`svg-inline--fa fa-${name}`"
  >
    <g
      v-if="collection === 'fad'"
      class="fa-duotone-group"
    >
      <path
        :d="path[0]"
        fill="currentColor"
        class="fa-secondary"
      />
      <path
        :d="path[1]"
        fill="currentColor"
        class="fa-primary"
      />
    </g>
    <path
      v-else
      :d="path"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
import * as icons from '~/lib/fontawesome.js'

type FaIcon = keyof typeof icons

const props = defineProps<{
  icon: string | [string, string]
}>()

const getIcon = () => {
  if (Array.isArray(props.icon)) {
    const collection = props.icon[0]
    const icon = props.icon[1]
    const iconName = `${collection}${formatAsPascalCase(icon)}` as FaIcon

    return icons[iconName]
  }

  const collection = 'fas'
  const iconName = `${collection}${formatAsPascalCase(props.icon)}` as FaIcon

  return icons[iconName]
}

const width = computed(() => getIcon().icon[0] || 0)
const height = computed(() => getIcon().icon[1] || 0)
const name = computed(() => getIcon().iconName || '')
const path = computed(() => (getIcon().icon[4] as string))
const collection = computed(() => getIcon().prefix)
</script>
